<template>
  <div class="home">
    <Services/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Services from '@/components/Services.vue'
import { useRouter, useRoute } from "vue-router";

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    Services
  }
}
</script>
