import {useToast} from 'vue-toast-notification';

const $toast = useToast();

const SucessNotification=(msg)=>{
    let instance = $toast.success(msg??"Task Executed Sucessfully");
}

const ErrorNotification=(msg)=>{
    let instance = $toast.error(msg??"Error While Executing Task");
}

const WarningNotification=(msg)=>{
    let instance = $toast.warning(msg??"Error While Executing Task");
}

const DefaultNotification=(msg)=>{
    let instance = $toast.default(msg??"Error While Executing Task");
}

export {SucessNotification,ErrorNotification,WarningNotification,DefaultNotification}