import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { supabase } from "@/lib/supabaseclient";

const requireAuth =async (to,from,next)=>{
  const { data: { user } } = await supabase.auth.getUser();
  console.log("Current User in Auth Guard is:"+user)
  if(!user){
    next({name:'home'})
  }
  next() 
  }

  const redirectToAdministration =async (to,from,next)=>{
    const { data: { user } } = await supabase.auth.getUser();
    console.log("Current User in Auth Guard is:"+user)
    if(user){
      next({name:'administration'})
    }
    next() 
    }
  
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/workers/:service',
    name: 'worker',
    component: () => import(/* webpackChunkName: "about" */ '../views/WorkerView.vue')
  },
  {
    path: '/administrationLogin',
    name: 'login',
    component: () =>  import(/* webpackChunkName: "about" */ '../views/AdministrationLogin.vue'),
    beforeEnter:redirectToAdministration
  },
  {
    path: '/administration/services',
    name: 'ManageServices',
    component: () => import(/* webpackChunkName: "about" */ '../views/ManageServicesView.vue'),
    beforeEnter:requireAuth

  },
  {
    path: '/administration/serviceproviders',
    name: 'ManageServiceProviders',
    component: () => import(/* webpackChunkName: "about" */ '../views/ServiceProviderView.vue'),
    beforeEnter:requireAuth
  },
  {
    path: '/administration',
    name: 'administration',
    component: () => import(/* webpackChunkName: "about" */ '../views/Administration.vue'),
    beforeEnter:requireAuth
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})




function GetLoginView()
{
  return import(/* webpackChunkName: "about" */ '../views/AdministrationLogin.vue');
}


export default router
