<template>
  <div>
    <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
      <div
        class="rounded-2xl bg-white border-2 hover:border-blue-700 place-content-center shadow-xl p-2 hover:-translate-y-6 hover:shadow-2xl transition ease-in-out delay-150 hover:scale-105 duration-200 cursor-pointer ;"
        v-for="item in services"
        v-on:click="onclickService(item)"
        :key="item.ServiceName"
      >
        <div class="flex flex-row justify-center">
          <img
            :src="getServiceImage(item)"
            style="width: 150px; height: 150px"
            class="rounded-2xl"
          />
        </div>

        <p class="capitalize text-sm text-center text-slate-600 font-semibold">
          {{ item.name }}
        </p>
      </div>
    </div>

    <!-- {{ services }} -->
  </div>
</template>

<script>
import { ref } from "vue";
import { read, writeFileXLSX, utils } from "xlsx";
import { useRouter, useRoute } from "vue-router";
import { supabase } from "@/lib/supabaseclient.js";
import {SucessNotification,ErrorNotification} from "@/Composables/Notification"

export default {
  name: "Services",
  setup() {
    const services = ref(null);
    const router = useRouter();
    let jsonData = [];

    async function LoadData() {
      const { data: serviceList, error } = await supabase
        .from("Services")
        .select("*");
      services.value = serviceList;
    }

    LoadData();

   

    function getDistinctElements(array) {
      const distinctElements = [];
      for (const element of array) {
        if (!distinctElements.includes(element)) {
          distinctElements.push(element);
        }
      }
      return distinctElements;
    }

    function getServiceImage(service) {
      if (service.iconUrl == null) {
        return "https://blogger.googleusercontent.com/img/a/AVvXsEhPvrL0s9CoshTWCR_nJ0oLNAieDZ7C7EJY4f974i_1iWjcDaK-P_wgLEoZEy2FszbZCXZqWceu4hd3SEHi_1Hq0C6mpF_R_cUuayr3s9NeuzFMPZaZXil0bfnVzFuxopR1zCb9p8cyUoDQwPsqRLcYDuROgoHJxFgiNOW7yZxVaODIfzCGRLjjWqt85A";
      } else {
        return service.iconUrl;
      }
    }

    function onclickService(service) {
      router.push({ name: "worker", params: { service: service.name } });
    }

    return { services, onclickService, getServiceImage };
  },
};
</script>
