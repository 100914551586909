<template>
 <nav class="bg-white">
  <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-2 	">
    <div class="relative flex h-24 items-center justify-between ">
      <div class="flex flex-1 items-center justify-center">
        <div class="flex flex-shrink-0 items-center">
          <img class="block "  style="height:65px;width:65px;" src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEifxwHkz_L1u0Kxgj9Sox2pU4qelUsT7uGymZf0TAdUYzCrWseuOMso5aktoaQdG6lKfN8mFGSRklqeTMY8I3EyZUEDeQjN5Ct11k4oJxZy8eVM2DaIxUjMk41yaRkxFTaSHAIZ_RS2VWP7zH301AwWUsj7rA0YUSIG48KbNTQzXR-XFIBYcb-6sH2SXg/s320/icon-1.png" alt="Bharat Mata">
        </div>
       
      </div>
   
    </div>
  </div>
</nav>
</template>

<script>
export default {
  name: 'Navbar',
}
</script>

