<template>
  <Navbar/>
  <div class="p-4 bg-slate-200 "> 
  <router-view/>
  </div>
</template>


<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import 'vue-toast-notification/dist/theme-sugar.css';

export default {
  name: 'HomeView',
  components: {
    Navbar
  }
}
</script>


<style>

</style>
